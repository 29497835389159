import {windshield, windshield2, windshield3, windshield4} from '../assets';


export const windshieldCarouselData = [
    {
        src: windshield,
        alt: 'Windshield'
    },
    {
        src: windshield2,
        alt: 'Windshield'
    },
    {
        src: windshield3,
        alt: 'Windshield'
    },
    {
        src: windshield4,
        alt: 'Windshield'
    },

]


