import {protect1, protect2, protect3, toning, toning1, toning2} from '../assets';


export const toningCarouselData = [
    {
        src: toning,
        alt: 'Protect'
    },
    {
        src: toning1,
        alt: 'Protect'
    },
    {
        src: toning2,
        alt: 'Protect'
    },
    {
        src: protect1,
        alt: 'Protect'
    },
    {
        src: protect2,
        alt: 'Protect'
    },
    {
        src: protect3,
        alt: 'Protect'
    },
]




