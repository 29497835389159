import React from 'react';

const Service = () => {
    return (
        <div>
            sad
        </div>
    );
};

export default Service;