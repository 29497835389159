import React from 'react';
import CustomCarousel from '../../components/custom-carousel/CustomCarousel';
import {headlightsCarouselData} from '../../data/headlights-carousel.data';
import {call} from '../../assets';


const Headlights = () => {
    return (
        <div className="carwash section-item block_wrap">
            <div className="section-item-right">
                <div className="section-item-left-header">
                    <p className="item-right-h">Уважаемые клиенты!<br/> <span className="span-item-right">ATV-Авто "Пулково"</span>,
                        предлагают вам услуги по восстановлению и уходу за головной оптикой:</p>
                    <p>
                        Восстановление прозрачности головной оптики - химическая полировка фар услуга крайне
                        востребованная. Встретить на современном автомобиле
                        стеклянные фары головного света сегодня практически невозможно, в отличие от пластиковых.
                        Основным минусом пластиковых фар является пескоструй,
                        мелкие трещины, помутнение и в результате - плохой, несфокусированный, рассеянный свет.
                        Пластиковая головная оптика плохо противостоит камням,
                        шипам,гравию летящему из под колес движущихся впереди автомобилей, особенно быстро теряют
                        прозрачность фары у автомобилей, которые чаще
                        передвигаются по загородным магистралям. Для восстановления прозрачности мы используем
                        технологию химической полировки фар. <br/>
                        «Традиционная» абразивная (механическая) полировка фар головного света, обычно практикуемая на
                        автомойках, обходится несомненно дешевле, но подходит она
                        только для слегка поцарапанных/затертых фар. В процессе химической полировки на поверхность
                        подготовленной фары наносится разогретый полимер,
                        который вступает в химическую реакцию с пластиком фары и превращается в защитное покрытие,
                        полностью повторяющее заводское.
                        В результате получаем практически новую фару с отличной светопропускной способностью и не
                        нарушенной фокусировкой светового потока.
                    </p>
                    <div className="contact_phone">
                        <img src={call} alt="call"/>
                        <a href="tel:3307115">Нажмите чтобы позвонить нам</a>
                    </div>
                </div>
            </div>
            <div className="section-item-left">
                <CustomCarousel items={headlightsCarouselData}/>
            </div>
        </div>
    );
};

export default Headlights;