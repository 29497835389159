import {conditioner1, conditioner2, conditioner3} from '../assets';


export const conditionerCarouselData = [
    {
        src: conditioner1,
        alt: 'Conditioner'
    },
    {
        src: conditioner2,
        alt: 'Conditioner'
    },
    {
        src: conditioner3,
        alt: 'Conditioner'
    },
]




