import React from 'react';
import CustomCarousel from '../../components/custom-carousel/CustomCarousel';
import {conditionerCarouselData} from '../../data/conditioner-carousel.data';
import {call} from '../../assets';

const Conditioner = () => {
    return (
        <div className="tires section-item">
            <div className="section-item-left">
                <CustomCarousel items={conditionerCarouselData}/>
            </div>
            <div className="section-item-right">
                <div className="section-item-left-header">
                    <p className="conditioner-text">
                        Предлагаем Вам ряд услуг по уходу за климатической системой Вашего автомобиля.
                        Таких как заправка кондиционера, диагностика системы и устранение выявленных неполадок.
                        Кондиционеры заправляются фреоном, который не допускает перегрева климатических устройств. У
                        него есть естественная норма испарения, равная 20-40 г в год. Но поскольку техника
                        эксплуатируется в тяжелых условиях, потери хладагента гораздо больше.
                        Заправка автокондиционера мастерами нашего сервиса производится комплексно, а это значит, что
                        при
                        заправке проводится осмотр системы на целостность, проверяется герметичность патрубков и мест
                        соединений. В случае обнаружения проблем осуществляется ремонт с последующей заменой
                        поврежденных или изношенных узлов.
                        Зачастую климатическая техника в рабочем состоянии распространяет по салону неприятный запах. Он
                        образуется из-за повышенной влажности в системе и неотфильтрованных микрочастиц пыли, мусора,
                        оседающих на металлических поверхностях. Чтобы устранить зловонный источник, потребуется
                        дезинфекция кондиционера. В зависимости от степени загрязнения осуществляется химическая чистка
                        техники или механическая, предполагающая демонтаж системы.
                        Наши специалисты компании менее, чем за час обновят хладагент климатической установки любого
                        авто. На заправку автокондиционера цена определяется объемом и видом фреона, необходимостью
                        проведения ремонтных работ, комплексом дополнительных услуг.
                    </p>
                </div>
                <p className="item-right-h">
                    Цены на заправку кондиционера <span className="span-item-right">от 2700 рублей</span> <br/>
                    Все работы выполняются с использованием современного оборудования.
                </p>
                <div className="contact_phone alternative">
                    <img src={call} alt="call"/>
                    <a href="tel:3307115">Нажмите чтобы позвонить нам</a>
                </div>
            </div>
        </div>
    );
};

export default Conditioner;