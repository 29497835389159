import {headlights1, headlightsAfter, headlightsBefore} from '../assets';


export const headlightsCarouselData = [
    {
        src: headlightsBefore,
        alt: 'Headlights Before'
    },
    {
        src: headlightsAfter,
        alt: 'Headlights After'
    },
    {
        src: headlights1,
        alt: 'Headlights After'
    },
]